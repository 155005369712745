import { BackendEnvironment } from "./backend-config.service"

export interface FirebaseConfig {
  module?: unknown;
  environment?: BackendEnvironment,
  apiKey: string,
  authDomain: string,
  databaseURL: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string,
}

const _backends: Record<string, Partial<FirebaseConfig>> = {
  "Emulator": { //! local emulator using exported data
    environment: 'Emulator',
    apiKey: "AIzaSyAxubgJ5_W6CjFJ9nHLgFcF9PAAwt4nNDY",
    authDomain: "superprep-dev.firebaseapp.com",
    databaseURL: "https://superprep-dev-default-rtdb.firebaseio.com",
    projectId: "superprep-dev",
    storageBucket: "superprep-dev.appspot.com",
    messagingSenderId: "1027062080183",
    appId: "1:1027062080183:web:d29ee3b7d602654e26e332",
    measurementId: "G-MQ83XY3MS3"
},
  "Local": {
    environment: 'Local',
    apiKey: "AIzaSyAxubgJ5_W6CjFJ9nHLgFcF9PAAwt4nNDY",
    authDomain: "superprep-dev.firebaseapp.com",
    databaseURL: "https://superprep-dev-default-rtdb.firebaseio.com",
    projectId: "superprep-dev",
    storageBucket: "superprep-dev.appspot.com",
    messagingSenderId: "1027062080183",
    appId: "1:1027062080183:web:d29ee3b7d602654e26e332",
    measurementId: "G-MQ83XY3MS3"
},
  "Development": {
    environment: 'Development',
  },
  "Staging": {
    environment: 'Staging',
  },
  "Production": {
    environment: 'Production',
  }
}

export function getFirebaseConfig<T extends FirebaseConfig>(data?: T): FirebaseConfig {
  const environment: BackendEnvironment = data?.environment ?? 'Local'
  const config = _backends[environment] as FirebaseConfig
  if (!data) { return config }
  if ((data.apiKey?.length??0) > 0 && !config.apiKey) { config.apiKey = data.apiKey }
  if ((data.authDomain?.length??0) > 0 && !config.authDomain) { config.authDomain = data.authDomain }
  if ((data.databaseURL?.length??0) > 0 && !config.databaseURL) { config.databaseURL = data.databaseURL }
  if ((data.projectId?.length??0) > 0 && !config.projectId) { config.projectId = data.projectId }
  if ((data.storageBucket?.length??0) > 0 && !config.storageBucket) { config.storageBucket = data.storageBucket }
  if ((data.messagingSenderId?.length??0) > 0 && !config.messagingSenderId) { config.messagingSenderId = data.messagingSenderId }
  if ((data.appId?.length??0) > 0 && !config.appId) { config.appId = data.appId }
  if ((data.measurementId?.length??0) > 0 && !config.measurementId) { config.measurementId = data.measurementId }
  return config
}
