import { inject, Injectable } from "@angular/core"
import { HttpStatusCode } from "@angular/common/http"

import { Observable, catchError, distinctUntilChanged, map, of, switchMap } from "rxjs"

import { Functions, httpsCallableData } from "@angular/fire/functions"
import { EventParams, getAnalytics, logEvent, provideAnalytics, setUserProperties } from "@angular/fire/analytics"

import { isEqual } from "lodash-es"

import { log } from "@nx-superprep/utils"
import { environment } from "@nx-superprep/environment"
import { BackendConfigService } from "@nx-superprep/backend/config"

import { ApplicationSetting, CardRetrievalRequest, ClassroomInformationResponse, ClassroomJoinRequest, concatCurrentPath, DayPlanRequest, DayPlanResponse, FrontendCard, FunctionStatusResponse, GroupTemplate, LibraryNavigation, Partner, PopApplication, StudyLibraryRequest, StudyLibraryResponse, StudySetting, User } from "../models"
import { UserService } from "./user.service"
import { DocumentData, DocumentReference, getDoc } from "@angular/fire/firestore"


@Injectable({ providedIn: 'root' })
export abstract class LongtailService {
  protected studyGetLibrary: (data: StudyLibraryRequest) => Observable<StudyLibraryResponse>
  protected studyGetDayPlan: (data: DayPlanRequest) => Observable<DayPlanResponse>
  protected studyGetCards: (data: CardRetrievalRequest ) => Observable<FrontendCard[]>
  protected requestClassroomInfo: (data: ClassroomJoinRequest ) => Observable<ClassroomInformationResponse>
  protected requestClassroomJoin: (data: ClassroomJoinRequest ) => Observable<FunctionStatusResponse>

  private _analytics = inject(provideAnalytics, { optional: true })

  private get analytics() {
    return this._analytics || getAnalytics()
  }
  private set analytics(value) {
    this._analytics = value || getAnalytics()
  }

  readonly backendService = inject(BackendConfigService)
  readonly userService = inject(UserService)
  readonly functions = inject(Functions)

  constructor() {
    this.studyGetLibrary = httpsCallableData(this.functions, 'study_get_library_v1')
    this.studyGetDayPlan = httpsCallableData(this.functions, 'study_get_day_plan_v1')
    this.studyGetCards = httpsCallableData(this.functions, 'study_get_cards_v1')
    this.requestClassroomInfo = httpsCallableData(this.functions, 'request_public_classroom_information_v1')
    this.requestClassroomJoin = httpsCallableData(this.functions, 'request_classroom_join_v1')
    this.userService.userData.pipe(switchMap((user) => this.onUserChange(user))).subscribe()
  }

  abstract clear(): void

  onUserChange(user: User | undefined): Observable<User | undefined> {
    if (this.clear) { this.clear() }
    setUserProperties(this.analytics, {app_name: environment.config.app_name, app_version:environment.config.version})
    return of(user as User | undefined)
  }

  lt_application = ['Staging', 'Production'].includes(this.backendService.firebase.environment??'') ? PopApplication.LT_PROD_WEB : PopApplication.LT_DEV_WEB

  get uid() {
    return this.userService.uid
  }

  // classroom

  validateClassroomCode(code: string)  {
    const data = {code, partner: Partner.POPTEAMS}
    return this.requestClassroomInfo(data)
    .pipe(
      catchError((error) => {
        const result = {statusCode: HttpStatusCode.NotFound, statusMessage: String(error.message)} as ClassroomInformationResponse
        return of(result)
      }),
      map(result => ({...result, statusCode: result.statusCode ?? HttpStatusCode.Ok }))
    )
  }

  joinClassroom(code: string) {
    const data = {code, partner: Partner.POPTEAMS}
    return this.requestClassroomJoin(data)
    .pipe(
      catchError((error) => {
        const result = {statusCode: HttpStatusCode.NotFound, statusMessage: String(error.message)} as ClassroomInformationResponse
        return of(result)
      }),
    )
  }

  // setting

  getAppSetting(uid = this.uid) {
    if (!uid) { log.debug(`no active user`); return of(new ApplicationSetting('', new StudySetting({}))) }
    return this.userService.getAppSetting(uid).pipe(distinctUntilChanged(isEqual))
  }

  setAppSetting(value: ApplicationSetting, uid = this.uid) {
    if (!uid) { return Promise.reject('no active user') }
    return this.userService.setAppSetting(value, uid)
  }

  // docs

  refExists(ref: DocumentReference<DocumentData, DocumentData>) {
    return getDoc(ref).then(s => s.exists()).catch(error => {log.debug(this.uid, error); return false})
  }

  // analytics

  private _logEvent(name: string, params: EventParams = {}) {
    logEvent(this.analytics, name, {app_name:environment.config.name, app_version:environment.config.version, ...params})
  }

  unsubscribe(): void {
    this.logUnsubscribeEvent()
  }

  logUnsubscribeEvent(): void {
    log.debug(`unsubscribe ${this.uid}`)
    this._logEvent('account_deletion', {uid:this.uid})
  }

  logSettingEvent(lesson_id: string|undefined, change: object = {}): void {
    if (Object.keys(change).length === 0) { return }
    const key = Object.keys(change).find(() => true)
    if (!key) { return}
    const event = {
      lesson_id: lesson_id || 'N/A',
      setting_option_change: Object(change)[key]
    }
    this._logEvent(`settings_change_${key}`, event)
  }

  logPerformanceFilterEvent(filter: GroupTemplate, state: LibraryNavigation | undefined) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this._logEvent('select_content', event)
  }

  logPerformanceEvent(value: string|undefined) {
    if (!value) { return }
    const event = {
      content_type: 'performance',
      item_id: value,
      location: `/performance/${value}`
    }
    this._logEvent('select_item', event)
  }

  logLibraryFilterEvent(filter: GroupTemplate, state: LibraryNavigation | undefined) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this._logEvent('select_content', event)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logLibraryEvent(value: any | undefined) {
    const section: string = value?.selection?.section?.title ?? value?.selection?.title ?? 'library'
    const selection: string = value?.selection?.item?.id ?? value?.selection?.item?.itemId
    const detail: string = value?.selection?.detail?.id
    const _itemId = detail ?? selection

    const event = _itemId ? {
      item_list_name: section,
      item_list_id: selection,
      items: [{item_id: _itemId, item_name: _itemId}],
      location: concatCurrentPath(value)
    } : {
      content_type: section ? section.toLowerCase() : 'library',
      item_id: section,
      location: concatCurrentPath(value)
    }

    if (event.content_type === 'library') { return }
    this._logEvent(_itemId ? 'select_item' : 'select_content', event)
  }

  logAppUpdate() {
    this._logEvent('app_update')
  }

  logAppOpen(component: string) {
    this._logEvent('app_open', {component})
  }

  logPageView(title: string, location?: string, path?: string) {
    this._logEvent('page_view', {page_title: title, page_location: location, page_path: path})
  }

  logSearch(keyword: string) {
    this._logEvent('search', {search_term: keyword})
  }

  logSelectContent(event: EventParams): void {
    this._logEvent('select_content', event)
  }

  logSelectItem(event: EventParams): void {
    this._logEvent('select_item', event)
  }

  logTutorialBegin() {
    this._logEvent('tutorial_begin')
  }

  logTutorialComplete() {
    this._logEvent('tutorial_complete')
  }

  logDailyPlanStart(event: EventParams) {
    this._logEvent('daily_lesson_plan_start', event)
  }

  logDailyPlanCompleted(event: EventParams) {
    this._logEvent('daily_lesson_plan_completed', event)
  }

  logDailyLessonStart(event: EventParams) {
    this._logEvent('daily_lesson_start', event)
  }

  logDailyLessonCompleted(event: EventParams) {
    this._logEvent('daily_lesson_completed', event)
  }

  logSessionStart(event: EventParams) {
    this._logEvent('study_session_start', event)
  }

  logSessionCompleted(event: EventParams) {
    this._logEvent('study_session_completed', event)
  }

  logLessonAdded(event: EventParams) {
    this._logEvent('lesson_added', event)
  }

  logLessonRemoved(event: EventParams) {
    this._logEvent('lesson_removed', event)
  }

  logLessonTryout(event: EventParams) {
    this._logEvent('lesson_try_out', event)
  }

  logLessonStart(event: EventParams) {
    this._logEvent('lesson_start', event)
  }

  logLessonCompleted(event: EventParams) {
    this._logEvent('lesson_completed', event)
  }

  logAccountDeletion(event: EventParams) {
    this._logEvent('account_deletion', event)
  }
}
