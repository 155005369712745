import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: InjectionToken<object>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public check(key: string): boolean {
    if (!this.isBrowser) {
      return false;
    }
    return this.document.cookie.includes(`${key}=`);
  }

  public set(
    key: string,
    value: string,
    until: Date | null = null,
    options?: {
      path?: string;
      secure?: boolean;
      sameSite?: 'Strict' | 'Lax' | 'None';
      domain?: string;
    }
  ): void {
    if (!this.isBrowser) {
      return;
    }

    const expires = until ? `; expires=${until.toUTCString()}` : '';
    const path = options?.path ? `; path=${options.path}` : '; path=/';
    const secure = options?.secure ? '; Secure' : '';
    const sameSite = `; SameSite=${options?.sameSite || 'Strict'}`;
    const domain = options?.domain ? `; domain=${options.domain}` : '';

    this.document.cookie = `${key}=${value}${expires}${path}${secure}${sameSite}${domain}`;
  }

  public get(key: string): string | null {
    if (!this.isBrowser) {
      return null;
    }

    const match = this.document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`));
    return match ? decodeURIComponent(match[2]) : null;
  }

  public deleteAll(): void {
    if (!this.isBrowser) {
      return;
    }

    const cookies = this.document.cookie.split(';');
    for (const cookie of cookies) {
      const [key] = cookie.split('=');
      this.set(key.trim(), '', new Date(0)); // Set expired date
    }
  }
}
