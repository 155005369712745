import { LibraryNavigation } from "./library-navigation"

export const concatCurrentPath = (nav: LibraryNavigation | undefined) => {
  let path = ''
  const detail = nav?.selection?.detail && ('id' in nav.selection.detail) ? nav.selection.detail.id ?? '' : ''

  // Traverse from the current navigation up to the root parent
  while (nav) {
    // Append the current route's title or route name to the path
    //const currentTitle = nav.navigation.current.title || nav.navigation.current.route
    // Append the current selected item id or route's title or name to the path
    const itemId = nav.selection?.item ? nav.selection?.item.itemId ?? (nav.selection && 'id' in nav.selection.item ? nav.selection.item.id : '') ?? '' : ''
    const currentTitle = itemId || nav.navigation.current.title || nav.navigation.current.route
    // Add the title/route to the path
    if (currentTitle) { path = `${currentTitle}${path ? '/' : ''}${path ?? ''}`}

    // Move to the parent navigation (if any)
    nav = nav.parent
  }

  return `${path}${detail ? '/' : ''}${detail}`
}
