import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { BehaviorSubject, Observable, catchError, combineLatest, distinctUntilChanged, firstValueFrom, map, of, tap } from 'rxjs'

import { Firestore, doc, docData, setDoc } from '@angular/fire/firestore'
import {
  Auth,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  sendEmailVerification,
  signInAnonymously,
  fetchSignInMethodsForEmail,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  onAuthStateChanged,
  User as BackendUser,
  UserCredential,
  OAuthProvider,
  AuthErrorCodes,
} from '@angular/fire/auth'
import { FirebaseError } from '@angular/fire/app'
import { Functions, httpsCallableData } from '@angular/fire/functions'

import { isEqual } from 'lodash-es'

//import { verifyBeforeUpdateEmail, verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from '@angular/fire/auth'

import { generatePassword, generateDisposableEmail, isDisposableEmail, isAnonymousEmail, log, stringToColor, tzguess } from '@nx-superprep/utils'
import { BrowserStorageService } from '@nx-superprep/backend/config'

import {
  ApplicationSetting,
  ClassroomJoinRequest,
  FunctionStatusResponse,
  PrivateUserInfo,
  UserInfo,
} from '../models/back-end'
import { User } from '../models/front-end'
import { defaultApplicationSetting, userApplicationSettingPath, userInfoPath, userPrivateInfoPath } from './default-setting'

export type ProviderId = 'google'|'apple'|undefined

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly userData = new BehaviorSubject<User | undefined>(undefined)

  private requestClassroomJoin: (data: ClassroomJoinRequest) => Observable<FunctionStatusResponse>

  private _userInfo!: UserInfo & PrivateUserInfo
  private _currentUserData: User | undefined = undefined
  private _appSetting: ApplicationSetting = defaultApplicationSetting(undefined)

  get currentUserData(): User | undefined {
    if (!this._currentUserData) {
      const data = this.browserStorage.getUser()
      const isSameUser = data?.auth?.authDomain === this.auth?.config.authDomain
      this._currentUserData = isSameUser ? data as User : undefined
    }
    if (this._currentUserData) { this._currentUserData.color = stringToColor(this._currentUserData.uid??'Guest'); }
    return this._currentUserData
  }
  set currentUserData(value) {
    this.browserStorage.setUser(value);
    this._currentUserData = value
    if (value) { this.userData.next(value) }
  }

  get isNew() {
    return this.auth.currentUser && this.auth.currentUser.metadata.creationTime === this.auth.currentUser.metadata.lastSignInTime
  }

  get uid() {
    return this.currentUserData?.uid
  }

  get isAnonymous() {
    return !!this.currentUserData?.isAnonymous
  }

  get userInfo() {
    return this._userInfo
  }
  set userInfo(value) {
    this._userInfo = value
  }

  get appSetting() {
    return this._appSetting
  }
  set appSetting(value) {
    this._appSetting = value ?? defaultApplicationSetting(this.uid)
  }

  errorMessage(error: FirebaseError|undefined) {
    if (!error) { return undefined }
    switch (error.code) {
      case AuthErrorCodes.ALREADY_INITIALIZED: return 'Email already in use.'
      case AuthErrorCodes.ARGUMENT_ERROR: return 'Invalid arguments.'
      case AuthErrorCodes.CAPTCHA_CHECK_FAILED: return 'Invalid captcha'
      case AuthErrorCodes.INVALID_CODE: return 'Verification code is invalid'
      case AuthErrorCodes.INVALID_EMAIL : return 'Email is invalid.'
      case AuthErrorCodes.INVALID_PASSWORD : return 'Invalid password'
      case AuthErrorCodes.NEED_CONFIRMATION: return "You already have an account with a different provider"
      case AuthErrorCodes.OPERATION_NOT_ALLOWED : return 'Operation not allowed'
      case AuthErrorCodes.POPUP_BLOCKED: return 'popup blocked'
      case AuthErrorCodes.POPUP_CLOSED_BY_USER: return 'popup closed by user'
      case AuthErrorCodes.PROVIDER_ALREADY_LINKED: return 'provider already linked'
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER: return 'Too many attempts, try again later.'
      case AuthErrorCodes.UNAUTHORIZED_DOMAIN: return 'Unauthorized domain.'
      case AuthErrorCodes.USER_DISABLED: return 'User disabled'
      case AuthErrorCodes.USER_CANCELLED: return 'User cancelled'
      case AuthErrorCodes.USER_DELETED: return 'User not found.'
      case AuthErrorCodes.USER_SIGNED_OUT: return 'User signed out'
      default: return 'An error occurred. Please try again later.'
    }
  }

  constructor(
    protected auth: Auth,
    protected router: Router,
    protected firestore: Firestore,
    protected functions: Functions,
    protected browserStorage: BrowserStorageService,
  ) {
    this.requestClassroomJoin = httpsCallableData(functions, 'request_classroom_join_v1')
    onAuthStateChanged(auth, (userData) => this.onUserChange(userData))
  }

  getUser() {
    return of(this.currentUserData)
  }

  onUserChange(userData: BackendUser | User | null) {
    if (userData) {
      this.onSignIn(userData)
    } else if (this.currentUserData) {
      if (isAnonymousEmail(this.currentUserData.email??'')) {
        this.onSignOut(this.currentUserData)
      } else {
        this.onSignIn(this.currentUserData)
      }
    }
  }

  onSignIn(userData: BackendUser|User) {
    log.debug(`🟩signed in ${isAnonymousEmail(userData.email??'')?'anonymous':isDisposableEmail(userData.email??'')?'disposable':'regular'}`, userData)
  }

  onSignOut(userData: BackendUser|User|undefined) {
    log.debug(`🟥signed out ${isAnonymousEmail(userData?.email??'')?'anonymous':isDisposableEmail(userData?.email??'')?'disposable':'regular'}`, userData)
  }

  verifyBeforeUpdateEmail(userData: BackendUser, newEmail: string) {
    log.debug(`verifyBeforeUpdateEmail ${userData?.uid} ${newEmail}`)
    //await verifyBeforeUpdateEmail(credential.user, newEmail, actionCodeSettings)
    // Obtain code from the user.
    //await applyActionCode(this.auth, code)
    return
  }

  exists(email: string) {
    return fetchSignInMethodsForEmail(this.auth, email).then(methods => methods.length > 0)
  }

  signInAnonymouslyFirebase() {
    return signInAnonymously(this.auth).catch((error) => log.debug(error))
  }

   signInAnonymously() {
     return this.signInAnonymouslyFirebase()
   }
   // temporary user for access to content, but not to main app
   signInTemporary() {
     return this.signUp(generateDisposableEmail(), generatePassword())
   }

  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  signInWithGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider())
  }
  signInWithApple() {
    return signInWithPopup(this.auth, new OAuthProvider('apple.com'))
  }

  // Sign in with email/password
  signIn(email: string, password: string, providerId?: ProviderId) {
    const credential = (_provider: ProviderId) => {
      switch(_provider) {
        case 'google': return this.signInWithGoogle()
        case 'apple': return this.signInWithApple()
        default : return this.signInWithEmailAndPassword(email, password)
      }
    }
    return credential(providerId)
  }

  // Sign up with email/password
  signUp(email: string, password: string, providerId?: ProviderId) {
    const credential = (_provider: ProviderId) => {
      switch(_provider) {
        case 'google': return this.signInWithGoogle()
        case 'apple': return this.signInWithApple()
        default : return createUserWithEmailAndPassword(this.auth, email, password)
      }
    }
    return credential(providerId).then((credential: UserCredential) => {
      //this.sendVerificationMail(credential.user)
      //this.setUserData(credential.user)
      return {...credential, isNew: true,  isSignUp: true }
    })
  }

  signOut(resetNavigation = true) {
    return signOut(this.auth).then(() => {
      this.browserStorage.removeUser()
      if (resetNavigation) {
        this.router.navigate(['website'], { skipLocationChange: true })
      }
    })
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail(user: User) {
    return sendEmailVerification(user)
      .then(() => {
        this.router.navigate(['verify-email-address'], { skipLocationChange: true })
      })
  }

  // Reset Forgot password
  resetPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.')
      })
  }

  firstRoute() {
    const route = this.router.url.split('?')[0] ?? ''
    if (!['/get-started','/demo'].includes(route)) {
      this.router.navigate(['dashboard'], { skipLocationChange: true })
    }
  }

  // change email
  async changeEmail(email: string, password: string) {
    if (!email || !password) { return }
    let user = this.auth.currentUser as User
    if (!user || !user.email) { return }
    const authCredential = EmailAuthProvider.credential(user.email, password)
    const credential = await reauthenticateWithCredential(user, authCredential)
    if (!credential.user.email) { return }
    await updateEmail(credential.user, email)
    user = {...user, ...credential.user, email}
    return await this.postSignIn(user)
  }

  // change password
  async changePassword(newPassword: string, password: string) {
    if (!newPassword || !password) { return }
    let user = this.auth.currentUser as User
    if (!user || !user.email) { return }
    const authCredential = EmailAuthProvider.credential(user.email, password)
    const credential = await reauthenticateWithCredential(user, authCredential)
    await updatePassword(credential.user, newPassword)
    //await sendPasswordResetEmail(this.auth, user.email, actionCodeSettings)
    // Obtain code from user.
    //await confirmPasswordReset(this.auth, code, newPassword)
    user = {...user, ...credential.user}
    return await this.postSignIn(user)
  }

  appSettingChanged(change: unknown, key: string) {
    return key === 'duration' && !!Object(change).key
  }

  getAppSetting(uid = this.uid) {
    const _userApplicationSettingPath = userApplicationSettingPath(uid)
    if (!_userApplicationSettingPath) { return of() }
    return navigator.onLine
    ? (docData(doc(this.firestore, _userApplicationSettingPath)))
      .pipe(
        catchError((val: FirebaseError) => { log.debug(val.stack); return of(this.appSetting) }),
        distinctUntilChanged(isEqual),
        tap(appSetting => this.appSetting = appSetting as ApplicationSetting),
        map(() => this.appSetting)
      )
    : of(this.appSetting)
  }

  setAppSetting(value: ApplicationSetting, uid = this.uid) {
    const _userApplicationSettingPath = userApplicationSettingPath(uid)
    if (!_userApplicationSettingPath) { return Promise.reject('no active user') }
    const ref = doc(this.firestore, _userApplicationSettingPath)
    return navigator.onLine ? setDoc(ref, ApplicationSetting.converter.toFirestore(value)).catch(error => { log.debug(value, error) }) : Promise.resolve()
  }

  setPublicInfo(info: UserInfo, uid = this.uid) {
    const _userInfoPath = userInfoPath(uid)
    if (!_userInfoPath) { return Promise.reject('no active user') }
    const ref = doc(this.firestore, _userInfoPath)
    return navigator.onLine ? setDoc(ref, UserInfo.converter.toFirestore(info)).catch(err => { log.debug(err); return Promise.resolve()}) : Promise.resolve()
  }

  setPrivateInfo(info: PrivateUserInfo, uid = this.uid) {
    const _userPrivateInfoPath = userPrivateInfoPath(uid)
    if (!_userPrivateInfoPath) { return Promise.reject('no active user') }
    const ref = doc(this.firestore, _userPrivateInfoPath)
    return navigator.onLine ? setDoc(ref, PrivateUserInfo.converter.toFirestore(info)).catch(err => { log.debug(err); return Promise.resolve()}) : Promise.resolve()
  }

  getInfo(uid = this.uid) {
    if (!uid) { return of() }
    const _userInfoPath = userInfoPath(uid)
    const _userPrivateInfoPath = userPrivateInfoPath(uid)
    if (!_userInfoPath || !_userPrivateInfoPath) { return of() }
    return navigator.onLine
    ? combineLatest([docData(doc(this.firestore, _userInfoPath)), docData(doc(this.firestore, _userPrivateInfoPath))])
      .pipe(
        catchError((val: FirebaseError) => { log.debug(val.stack); return of(this.userInfo) }),
        distinctUntilChanged(isEqual),
        tap(info => this.userInfo = ({...info[0], ...info[1]} as UserInfo & PrivateUserInfo)),
        map(() => this.userInfo)
      )
    : of(this.userInfo)
  }

  setInfo(value: { firstName?: string; lastName?: string; timeZone?: string; profileName?: string }, uid = this.uid) {
    if (!uid) { return Promise.reject('no active user') }
    const firstName = value.firstName ?? ''
    const lastName = value.lastName ?? ''
    const profileName = value.profileName ?? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
    const publicInfo = new UserInfo(uid, profileName)
    const privateUserInfo = new PrivateUserInfo(uid, firstName, lastName)
    privateUserInfo.timeZone = value.timeZone ?? tzguess()
    return this.setPrivateInfo(privateUserInfo, uid)
    .then(() => { this.setPublicInfo(publicInfo, uid) })
    .then(() => true)
    .catch(() => false)
  }

  async postSignIn(user: User) {
    if (!user) { return }
    user.isConversion = user.isAnonymous && !user.password
    user.isAnonymous = !!user.password
    user.isSignup = !user.isConversion && !!user.password
    const isNew = user.metadata?.creationTime === user.metadata?.lastSignInTime
    const source = isNew ? of({firstName: user.firstName, lastName: user.lastName, profileName: user.profileName}) : this.getInfo(user.uid)
    try {
      const info = await firstValueFrom(source)
      if (!info) { return }
      const firstName = (info.firstName??'').length > 0 ? info.firstName : (user.email??'')[0].toUpperCase()
      const lastName = (info.lastName??'').length > 0 ? info.lastName : (user.email??'')[1].toUpperCase()
      const timeZone = tzguess()
      const profileName = (info.profileName??'').length > 0 ? info.profileName : user.displayName ?? 'Guest'
      await this.setInfo({ firstName, lastName, timeZone, profileName }, user.uid)
      this.currentUserData = user
    } catch (error) {
      log.debug(error)
    }
    return user
  }

  forgotUsername(email: string) {
    log.debug(email)
    return Promise.resolve()
  }

  joinClassroom(request?: ClassroomJoinRequest) {
    return request ? this.requestClassroomJoin(request) : of()
  }
}
