import { QueryDocumentSnapshot } from "@angular/fire/firestore"
import { isEqual } from "lodash-es"

import { StudySetting as BackendStudySetting } from '@longtail-education/superprepstudy-data-model-frontend'

/**
 * Represents a study setting, extending the backend study setting.
 */
export class StudySetting implements BackendStudySetting {
  duration: number
  difficulty: number
  animation: boolean
  soundEffect: boolean

  static readonly default = { duration: 2, difficulty: 2, soundEffect: true, animation: true }

  /** The maximum duration value allowed for a study setting. */
  static maxDuration = 4
  /** The maximum difficulty value allowed for a study setting. */
  static maxDifficulty = 4

  /**
   * Creates an instance of StudySetting.
   * @param options Optional parameters to initialize the study setting.
   */
  constructor(options?: Partial<StudySetting>) {
    this.duration = Number(options?.duration ?? StudySetting.default.duration)
    this.difficulty = Number(options?.difficulty ?? StudySetting.default.difficulty)
    this.animation = Boolean(options?.animation ?? StudySetting.default.animation)
    this.soundEffect = Boolean(options?.soundEffect ?? StudySetting.default.soundEffect)
  }

  /**
   * Firestore data converter for StudySetting.
   */
  static converter = {
    toFirestore(value: StudySetting) {
      return {
        duration: Math.min(Math.abs(Number(value.duration) || 0), StudySetting.maxDuration),
        difficulty: Math.min(Math.abs(Number(value.difficulty) || 0), StudySetting.maxDifficulty),
        animation: Boolean(value.animation),
        soundEffect: Boolean(value.soundEffect),
      }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): StudySetting {
      return new StudySetting(snapshot.data())
    }
  }

  /**
   * Returns the maximum value between two study settings.
   * @param a The first study setting.
   * @param b The second study setting.
   * @returns The study setting with maximum values from both.
   */
  static max(a: StudySetting, b: StudySetting): StudySetting {
    return new StudySetting({
      duration: Math.max(a?.duration ?? 0, b?.duration ?? 0),
      difficulty: Math.max(a?.difficulty ?? 0, b?.difficulty ?? 0)
    })
  }

  /**
   * Checks if two study settings are equal.
   * @param a The first study setting.
   * @param b The second study setting.
   * @returns True if the study settings are equal, otherwise false.
   */
  static isEqual(a: StudySetting, b: StudySetting): boolean {
    return isEqual(a, b)
  }

  /**
   * Checks if this study setting has the same value as another study setting.
   * @param d The study setting to compare.
   * @returns True if both study settings have the same value, otherwise false.
   */
  isSameValue(d: StudySetting) {
    return StudySetting.isEqual(this, d)
  }
}
