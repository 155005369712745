import { Platform } from '@angular/cdk/platform';
import { isPlatformServer } from '@angular/common';
import {
  EventEmitter,
  Inject,
  Injectable,
  PLATFORM_ID,
  ViewContainerRef,
  EnvironmentInjector, OnDestroy
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmartBannerComponent } from './smart-banner.component';
import { SmartBannerPlatform, SmartBannerSettings } from './settings.interface';
import { CookieService } from './utils/cookie.service';

@Injectable({ providedIn: 'root' })
export class SmartBannerService implements OnDestroy {
  private readonly isServer: boolean;
  public settings: SmartBannerSettings;
  private smartBanner: SmartBannerComponent | undefined = undefined;
  public onOpen: EventEmitter<void>;
  public onClose: EventEmitter<void>;
  private onCloseSubscription$: Subscription | undefined;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly platform: Platform,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly cookieService: CookieService,
    private readonly injector: EnvironmentInjector
  ) {
    this.onOpen = new EventEmitter();
    this.onClose = new EventEmitter();
    this.isServer = isPlatformServer(this.platformId);
    this.settings = this.defaultSettings();
  }

  private defaultSettings(): SmartBannerSettings {
    return {
      title: 'Smart application',
      author: 'Smartbanner contributors',
      price: 'FREE',
      closeLabel: 'Close',
      buttonLabel: 'VIEW',
      enabledPlatforms: [SmartBannerPlatform.Android, SmartBannerPlatform.IOS],
      viewContainerRef: null,
      priceSuffix: { ios: ' - On the App Store', android: ' - In Google Play' },
      rating: { ios: 5, android: 5 },
      hideRating: false,
    };
  }

  public initialize(settings: SmartBannerSettings): void {
    this.settings = { ...this.settings, ...settings };

    if (!this.settings.viewContainerRef) {
      throw new Error('No view container ref provided');
    }

    if (this.isServer || this.cookieService.check('smartbanner_closed')) {
      return;
    }

    if (!this.platformEnabled) {
      return;
    }

    if (!this.smartBanner) {
      const viewContainerRef = this.settings.viewContainerRef as ViewContainerRef;
      const componentRef = viewContainerRef.createComponent(SmartBannerComponent, { environmentInjector: this.injector });

      this.onOpen.emit();
      this.smartBanner = componentRef.instance;
      this.smartBanner.componentRef = componentRef;

      this.onCloseSubscription$ = this.smartBanner.onClose
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onClose.emit();
          this.onCloseSubscription$?.unsubscribe();
        });
    }

    this.smartBanner.settings = this.settings;
  }

  private get platformEnabled(): boolean {
    const platformEnabled = this.platform.ANDROID
      ? SmartBannerPlatform.Android
      : this.platform.IOS
      ? SmartBannerPlatform.IOS
      : undefined;
    return !!platformEnabled && !!this.settings.enabledPlatforms?.includes(platformEnabled);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
