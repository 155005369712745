/**
 * Represents a request to join a classroom.
 */
export interface ClassroomJoinRequest {
  /** The code used to join the classroom. */
  code: string
  /** The partner associated with the request. */
  partner: Partner
}

/**
 * Represents the response status of a function.
 */
export interface FunctionStatusResponse {
  /** The status code of the response. */
  statusCode: number
  /** The status message of the response, if available. */
  statusMessage: string | undefined
}

/**
 * Enum representing different partners.
 */
export enum Partner {
  /** The POPTEAMS partner. */
  POPTEAMS = "partner_popteams"
}
