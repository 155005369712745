import lastnames from './lastnames'
import surnames from './surnames'
import domains from './domains'

const dictionarySelect = (dictionary: 'surname'|'lastname'|'domain') => {
  const d = dictionary === 'domain' ? domains : dictionary === 'lastname' ? lastnames : surnames
  return d[Math.floor(Math.random() * d.length)]
}

export const POP_ANONYMOUS_DOMAIN = 'anonymous.local'
export const POP_DISPOSABLE_DOMAIN = 'disposable.local'

export const generatePassword = () => Math.random().toString(36).slice(-8)
export const generateUsername = () => `${dictionarySelect('surname')}.${dictionarySelect('lastname')}${Math.floor(Math.random()*9999)}`
export const generateEmail = () => `${generateUsername()}@${dictionarySelect('lastname')}.${dictionarySelect('domain')}`
export const generateAnonymousEmail = () => `${generatePassword()}@${POP_ANONYMOUS_DOMAIN}`
export const isAnonymousEmail = (email?: string|null) => !email || email.endsWith(`@${POP_ANONYMOUS_DOMAIN}`)
export const generateDisposableEmail = () => `${generatePassword()}@${POP_DISPOSABLE_DOMAIN}`
export const isDisposableEmail = (email?: string|null) => !email || email.endsWith(`@${POP_DISPOSABLE_DOMAIN}`)
