import { bootstrapApplication } from '@angular/platform-browser'
import { enableProdMode, mergeApplicationConfig, PLATFORM_ID } from '@angular/core'

import { appConfig } from './app/app.config'
import { AppComponent } from './app/app.component'

import { environment } from '@nx-superprep/environment'

import npmPackage from 'package.json'

import { fetchConfig } from '@nx-superprep/backend/config'

import * as Sentry from "@sentry/angular"
import { log } from '@nx-superprep/utils'

const bootstrap = () => fetchConfig(environment.config)
.then((config) => {
  log.debug(PLATFORM_ID, config)
  environment.config = config
  bootstrapApplication(AppComponent, mergeApplicationConfig(appConfig, { providers: [ { provide: PLATFORM_ID, useValue: 'browser' } ] }))
})
.catch(error => console.error(error))

const start = environment.production
? () => {
    enableProdMode()
    Sentry.init({
      dsn: "https://d8e6e4b57f846c11d40ffcfa332b7a00@o4507301716951040.ingest.us.sentry.io/4507301722324992",
      enabled: environment.production,
      release: `popstudy@${npmPackage.version}`,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration({levels:['warn','error']}),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      ignoreErrors: ['Network request failed', 'Failed to fetch', 'NetworkError', 'withrealtime/messaging'],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/popstudy\.com\/api/],
      // Profiling
      // Set profilesSampleRate to 1.0 to profile every transaction.
      // Since profilesSampleRate is relative to tracesSampleRate,
      // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      // results in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0,
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: !environment.config?.isProduction ? 1.0 : 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });
    Sentry.startSpan(
      { name: 'bootstrap-angular-application', op: 'ui.angular.bootstrap' },
      bootstrap
    )
  }
: bootstrap

//document.addEventListener('DOMContentLoaded', () => {
  start()
//})
