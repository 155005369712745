import { Component, Input } from '@angular/core'

@Component({
    selector: 'lib-smart-banner-star',
    standalone: true,
    templateUrl: './star.component.html',
    styleUrls: ['../smart-banner.component.scss'],
})
export class StarComponent {
    @Input() public solid = false
}
