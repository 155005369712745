import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConsentService {
  private readonly CONSENT_KEY = 'notification-consent'

  getConsent(): boolean | null {
    const value = localStorage.getItem(this.CONSENT_KEY)
    return value === null ? null : value === 'true'
  }

  setConsent(granted: boolean): void {
    localStorage.setItem(this.CONSENT_KEY, String(granted))
  }

  clearConsent(): void {
    localStorage.removeItem(this.CONSENT_KEY)
  }
}
