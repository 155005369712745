import { PrivateUserInfo, UserInfo } from "@longtail-education/superprep-data-model-frontend"
import { ApplicationSetting } from "@longtail-education/superprepstudy-data-model-frontend"

export function userApplicationSettingPath(uid: string | undefined) {
  return uid && ApplicationSetting.collectionPath() ? `${ApplicationSetting.collectionPath()}/${uid}` : undefined
}
export function userPrivateInfoPath(uid: string | undefined) {
  return uid && PrivateUserInfo.collectionPath(uid) ? `${PrivateUserInfo.collectionPath(uid)}/${uid}` : undefined
}
export function userInfoPath(uid: string | undefined) {
  return uid && UserInfo.collectionPath() ? `${UserInfo.collectionPath()}/${uid}` : undefined
}
export function defaultApplicationSetting(uid: string | undefined) {
  return ApplicationSetting.defaultApplicationSetting(uid ?? '') as ApplicationSetting
}
