import {
  distantFuture,
  distantPast,
  MAX_TIMESTAMP,
  datelib,
  DatelibType,
  ConfigType,
  ManipulateType
} from './date-adapter'

//-----------------------------------------------------------------------------
// Constants
//-----------------------------------------------------------------------------

export const INTERVALS = {
  year: true,
  quarter: true,
  month: true,
  week: true,
  day: true,
  hour: true,
  minute: true,
  second: true,
}

export type DateRangeOptions = Partial<{
  exclusive: boolean
  excludeStart: boolean
  excludeEnd: boolean
  step: number
}>

//-----------------------------------------------------------------------------
// Date Ranges
//-----------------------------------------------------------------------------

export class DateRange {
  start: DatelibType
  end: DatelibType

  constructor(start: ConfigType, end: ConfigType) {
    let s = start
    let e = end

    if (arguments.length === 1 || end === undefined) {
      if (datelib(start).isValid()) {
        [s, e] = [start, null]
      } else if (typeof start === 'string') {
        [s, e] = isoSplit(start)
      }
    }

    this.start = s || s === 0 ? datelib(s) : datelib(-MAX_TIMESTAMP)
    this.end = e || e === 0 ? datelib(e) : datelib(MAX_TIMESTAMP)
  }

  adjacent(other: DateRange) {
    const sameStartEnd = this.start.isSame(other.end)
    const sameEndStart = this.end.isSame(other.start)

    return (
      (sameStartEnd && other.start.valueOf() <= this.start.valueOf()) ||
      (sameEndStart && other.end.valueOf() >= this.end.valueOf())
    )
  }

  add(other: DateRange, options = { adjacent: false }) {
    if (this.overlaps(other, options)) {
      return new DateRange(
        datelib.min(this.start, other.start),
        datelib.max(this.end, other.end)
      )
    }

    return null
  }

  by(
    interval: ManipulateType,
    options: DateRangeOptions = { excludeEnd: false, step: 1 }
  ) {
    const range = () => this

    return {
      [Symbol.iterator]: () => {
        const step = options.step || 1
        const diff = Math.abs(range().start.diff(range().end, interval)) / step
        let excludeEnd = options.excludeEnd || false
        let iteration = 0

        if (Object.prototype.hasOwnProperty.call(options, 'exclusive')) {
          excludeEnd = Boolean(options.exclusive)
        }

        return {
          next: () => {
            const current = range()
              .start.clone()
              .add(iteration * step, interval)
            const done = excludeEnd
              ? !(iteration < diff)
              : !(iteration <= diff)

            iteration++

            return {
              done,
              value: done ? undefined : current,
            }
          },
        }
      },
    }
  }

  byRange(
    interval: number,
    options: DateRangeOptions = { excludeEnd: false, step: 1 }
  ) {
    const range = () => this
    const step = options.step || 1
    const diff = this.valueOf() / interval.valueOf() / step
    const unit = Math.floor(diff)
    let excludeEnd = options.excludeEnd || false
    let iteration = 0

    if (Object.prototype.hasOwnProperty.call(options, 'exclusive')) {
      excludeEnd = Boolean(options.exclusive)
    }

    return {
      [Symbol.iterator]: () => {
        if (unit === Infinity) {
          return { done: true }
        }

        return {
          next: () => {
            const current = datelib(
              range().start.valueOf() + interval.valueOf() * iteration * step
            )
            const done =
              unit === diff && excludeEnd
                ? !(iteration < unit)
                : !(iteration <= unit)

            iteration++

            return {
              done,
              value: done ? undefined : current,
            }
          },
        }
      },
    }
  }

  center() {
    const center = this.start.valueOf() + this.diff() / 2

    return datelib(center)
  }

  clone() {
    return new DateRange(this.start.clone(), this.end.clone())
  }

  contains(
    other: Date,
    options: DateRangeOptions = { excludeStart: false, excludeEnd: false }
  ) {
    const start = this.start.valueOf()
    const end = this.end.valueOf()
    let oStart = other.valueOf()
    let oEnd = other.valueOf()
    let excludeStart = options.excludeStart || false
    let excludeEnd = options.excludeEnd || false

    if (Object.prototype.hasOwnProperty.call(options, 'exclusive')) {
      excludeStart = excludeEnd = Boolean(options.exclusive)
    }

    if (other instanceof DateRange) {
      oStart = other.start.valueOf()
      oEnd = other.end.valueOf()
    }

    const startInRange = start < oStart || (start <= oStart && !excludeStart)
    const endInRange = end > oEnd || (end >= oEnd && !excludeEnd)

    return startInRange && endInRange
  }

  diff(unit?: ManipulateType, precise?: undefined) {
    return this.end.diff(this.start, unit, precise)
  }

  duration(unit: ManipulateType, precise: undefined) {
    return this.diff(unit, precise)
  }

  intersect(other: DateRange) {
    const start = this.start.valueOf()
    const end = this.end.valueOf()
    const otherStart = other.start.valueOf()
    const otherEnd = other.end.valueOf()
    const isZeroLength = start === end
    const isOtherZeroLength = otherStart === otherEnd

    // Zero-length ranges
    if (isZeroLength) {
      const point = start

      if (point === otherStart || point === otherEnd) {
        return null
      } else if (point > otherStart && point < otherEnd) {
        return this.clone()
      }
    } else if (isOtherZeroLength) {
      const point = otherStart

      if (point === start || point === end) {
        return null
      } else if (point > start && point < end) {
        return new DateRange(point, point)
      }
    }

    // Non zero-length ranges
    if (start <= otherStart && otherStart < end && end < otherEnd) {
      return new DateRange(otherStart, end)
    } else if (otherStart < start && start < otherEnd && otherEnd <= end) {
      return new DateRange(start, otherEnd)
    } else if (otherStart < start && start <= end && end < otherEnd) {
      return this.clone()
    } else if (
      start <= otherStart &&
      otherStart <= otherEnd &&
      otherEnd <= end
    ) {
      return new DateRange(otherStart, otherEnd)
    }

    return null
  }

  isEqual(other: DateRange) {
    return this.start.isSame(other.start) && this.end.isSame(other.end)
  }

  isSame(other: DateRange) {
    return this.isEqual(other)
  }

  overlaps(other: DateRange, options = { adjacent: false }) {
    const intersects = this.intersect(other) !== null

    if (options.adjacent && !intersects) {
      return this.adjacent(other)
    }

    return intersects
  }

  reverseBy(
    interval: ManipulateType,
    options: DateRangeOptions = { excludeStart: false, step: 1 }
  ) {
    const range = () => this

    return {
      [Symbol.iterator]: () => {
        const step = options.step || 1
        const diff = Math.abs(range().start.diff(range().end, interval)) / step
        let excludeStart = options.excludeStart || false
        let iteration = 0

        if (Object.prototype.hasOwnProperty.call(options, 'exclusive')) {
          excludeStart = Boolean(options.exclusive)
        }

        return {
          next: () => {
            const current = range()
              .end.clone()
              .subtract(iteration * step, interval)
            const done = excludeStart
              ? !(iteration < diff)
              : !(iteration <= diff)

            iteration++

            return {
              done,
              value: done ? undefined : current,
            }
          },
        }
      },
    }
  }

  reverseByRange(
    interval: number,
    options: DateRangeOptions = { excludeStart: false, step: 1 }
  ) {
    const range = () => this
    const step = options.step || 1
    const diff = this.valueOf() / interval.valueOf() / step
    const unit = Math.floor(diff)
    let excludeStart = options.excludeStart || false
    let iteration = 0

    if (Object.prototype.hasOwnProperty.call(options, 'exclusive')) {
      excludeStart = Boolean(options.exclusive)
    }

    return {
      [Symbol.iterator]: () => {
        if (unit === Infinity) {
          return { done: true }
        }

        return {
          next: () => {
            const current = datelib(
              range().end.valueOf() - interval.valueOf() * iteration * step
            )
            const done =
              unit === diff && excludeStart
                ? !(iteration < unit)
                : !(iteration <= unit)

            iteration++

            return {
              done,
              value: done ? undefined : current,
            }
          },
        }
      },
    }
  }

  snapTo(interval: ManipulateType) {
    const r = this.clone()

    // Snap if not open-ended range
    if (!r.start.isSame(datelib(-8640000000000000))) {
      r.start = r.start.startOf(interval)
    }
    if (!r.end.isSame(datelib(8640000000000000))) {
      r.end = r.end.endOf(interval)
    }

    return r
  }

  subtract(other: DateRange) {
    const start = this.start.valueOf()
    const end = this.end.valueOf()
    const oStart = other.start.valueOf()
    const oEnd = other.end.valueOf()

    if (this.intersect(other) === null) {
      return [this]
    } else if (oStart <= start && start < end && end <= oEnd) {
      return []
    } else if (oStart <= start && start < oEnd && oEnd < end) {
      return [new DateRange(oEnd, end)]
    } else if (start < oStart && oStart < end && end <= oEnd) {
      return [new DateRange(start, oStart)]
    } else if (start < oStart && oStart < oEnd && oEnd < end) {
      return [new DateRange(start, oStart), new DateRange(oEnd, end)]
    } else if (start < oStart && oStart < end && oEnd < end) {
      return [new DateRange(start, oStart), new DateRange(oStart, end)]
    }

    return []
  }

  toDate() {
    return [this.start.toDate(), this.end.toDate()]
  }

  toString() {
    return this.start.format() + '/' + this.end.format()
  }

  valueOf() {
    return this.end.valueOf() - this.start.valueOf()
  }
}

//-----------------------------------------------------------------------------
// Utility Functions
//-----------------------------------------------------------------------------

/**
 * Splits an iso string into two strings.
 */
const isoSplit = (isoString: string) => isoString.split('/')

export const openRange = new DateRange(distantPast, distantFuture)
