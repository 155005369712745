import { ViewContainerRef } from '@angular/core';

export interface SmartBannerSettings {
  title?: string;
  author?: string;
  price?: string;
  priceSuffix?: {
    ios?: string;
    android?: string;
  };
  rating?: {
    ios?: number;
    android?: number;
  };
  icon?: {
    ios?: string;
    android?: string;
  };
  buttonLabel?: string;
  buttonUrl?: {
    ios?: string;
    android?: string;
  };
  closeLabel?: string;
  enabledPlatforms?: Array<SmartBannerPlatform>;
  viewContainerRef: ViewContainerRef | null;  // Required field to be provided
  daysHidden?: number;   // Default `null` -> optional `undefined`
  daysReminder?: number; // Default `null` -> optional `undefined`
  hideRating?: boolean;
}

export enum SmartBannerPlatform {
  Android = 'android',
  IOS = 'ios',
}
