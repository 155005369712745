<main>
  <router-outlet></router-outlet>
</main>

<div class="centered-tour-element image" tourAnchor="anchor.centered.image"></div>
<div class="centered-tour-element icon" tourAnchor="anchor.centered.icon"></div>

<tour-step-template>
  <ng-template let-step="step">
    @if (isCenteredAnchor(step.anchorId)) {
      <div class="tour-step-modal" (click)="$event.stopPropagation()">
        <div><img [src]="step.icon" class="tour-icon"/></div>
        <div class="tour-step-content">
          <div><img [src]="step.image" class="tour-image" [style.width]="step.imageWidth"/></div>
          <div class="tour-title">{{ step.title }}</div>
          <div class="tour-content" [innerHTML]="step.content"></div>
          <button
            class="tour-end"
            mat-flat-button
            color="primary"
            (click)="step.endBtnTitle = step.nextBtnTitle; tourService.end()"
          >
            {{ step.nextBtnTitle }}
          </button>
        </div>
      </div>
    }
    @else {
    <mat-card
      class="tour-step-card"
      (click)="$event.stopPropagation()"
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
    >
      <mat-card-content
        class="tour-content"
        [innerHTML]="step.content"
      ></mat-card-content>

      <mat-card-actions
        class="tour-actions"
        [class.no-progress]="!step.showProgress"
        [class.center]="step.endBtnTitle && !step.prevBtnTitle"
        [class.end]="step.endBtnTitle && step.prevBtnTitle"
      >
        @if(step.prevStep) {
          <button
            mat-flat-button
            class="tour-prev"
            color="primary"
            [disabled]="!tourService.hasPrev(step)"
            (click)="step.endBtnTitle = step.prevBtnTitle; tourService.prev()"
          >
            {{ step.prevBtnTitle }}
          </button>
        }
        @if (step.showProgress) {
          <div class="tour-progress">{{ tourService.steps.indexOf(step) + 1 }} / {{ tourService.steps.length }}</div>
        }
        @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
          <button
            class="tour-next"
            color="primary"
            (click)="step.endBtnTitle = step.nextBtnTitle; tourService.next()"
            mat-flat-button
          >
            {{ step.nextBtnTitle }}
          </button>
        }
        @if (!tourService.hasNext(step)) {
          <button
            class="tour-end"
            mat-flat-button
            color="primary"
            (click)="step.endBtnTitle = step.nextBtnTitle; tourService.end()"
          >
            {{ step.endBtnTitle }}
          </button>
        }
      </mat-card-actions>
    </mat-card>
    }
  </ng-template>
</tour-step-template>
