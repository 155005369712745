import { routeForSelection } from "./route-selection"

export type PerformanceRouteType = 'graph'
export type HeaderTemplateType = typeof HeaderTemplateValues[number]
export type DashboardRouteType = HeaderTemplateType|'profile'|'legal'
export type NavigationTitleType = string | [string, string, string] | undefined
export type NavigationType = 'root' | 'back' | 'nav' | 'close' | 'close-start' | 'close-end'
export type NavigationAction = 'close'|'back'|'forward'|'title'
export type NavigationTabName = 'start'|'center'|'end'

export type LibraryRouteType = ReturnType<typeof routeForSelection>

export const HeaderTemplateValues = <const> ['home','library','performance','brushup','resources']
