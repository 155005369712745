import { Injectable } from '@angular/core'
import { log } from '@nx-superprep/utils'

type DeviceOrientationType = OrientationType | 'portrait' | 'landscape'

declare global {
  interface ScreenOrientation {
    lock(orientation: DeviceOrientationType): Promise<void>;
  }
}

@Injectable({providedIn: 'root'})
export class OrientationService {

  _orientationType(value: DeviceOrientationType ) { return value === 'portrait' || value.startsWith('portrait') ? 'portrait' : 'landscape' }

  async lockOrientation(value?: DeviceOrientationType, fullscreen = true) {
    if (!value || this.getOrientation() === this._orientationType(value)) { return false }
    try {
      //! Missing 'lock' method (https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1615)
      if ('lock' in screen.orientation && typeof screen.orientation.lock === 'function') {
        await this.fullScreenCheck({navigationUI:'hide'})
        await screen.orientation.lock(value)
        log.debug(`locked into ${value} orientation`)
        if (!fullscreen) { await document.exitFullscreen() }
        return true
      } else {
        return false
      }
    } catch(error) {
      log.debug(`orientation error ${error}`)
      return false
    }
  }

  fullScreenCheck(options?: FullscreenOptions) {
    return this.fullScreenCheckElement(document.documentElement, options)
  }

  async fullScreenCheckElement(element: HTMLElement, options?: FullscreenOptions) {
    if (document.fullscreenElement || !element) {
      log.debug('Already in fullscreen or invalid element.')
      return
    }
    try {
      await element.requestFullscreen(options)
    }
    catch(error) {
      log.debug(`full screen check error ${error}`)
    }
  }

  updateDetails(lockButton: HTMLElement) {
    const buttonOrientation = this.getOppositeOrientation()
    lockButton.textContent = `Lock to ${buttonOrientation}`
  }

  getOrientation() {
    return this._orientationType(screen.orientation.type??'')
  }

  getOppositeOrientation() {
    return this.getOrientation() === 'portrait' ? 'landscape' : 'portrait'
  }

  get isPortrait() {
    return this.getOrientation() === 'portrait'
  }
  get isLandscape() {
    return !this.isPortrait
  }

  async rotate() {
    try {
      await this.fullScreenCheck()
    } catch (err) {
      log.error(err)
    }
    const newOrientation = this.getOppositeOrientation()
    await this.lockOrientation(newOrientation)
  }

  show() {
    const { type, angle } = screen.orientation
    log.debug(`Orientation type is ${type} & angle is ${angle}.`)
  }
}
