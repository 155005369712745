import { Timestamp } from '@angular/fire/firestore'
import { ConfigType, DatelibType, ManipulateType, datelib } from './date-adapter'

export type DateOnlyManipulateType = Exclude<ManipulateType, 'millisecond' | 'second' | 'minute' | 'hour' | 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'ms' | 's' | 'm' | 'h'>

export type DateOnlyConfigType = ConfigType | DateOnly | Timestamp

export class DateOnly {
  static diff(aDate: DateOnlyConfigType, bDate: DateOnlyConfigType = new DateOnly(), granularity: DateOnlyManipulateType = 'day') {
    const a = new DateOnly(aDate).toDatelib()
    if (!a.isValid()) { return NaN }
    const b = new DateOnly(bDate).toDatelib()
    if (!b.isValid()) { return NaN }
    return b.diff(a, granularity)
  }

  private _year = 0
  private _month = 0
  private _date = 0

  constructor(date: DateOnlyConfigType = new Date().toDateString()) {
    let value
    if (date instanceof Date) {
      value = new Date(date.toDateString())
    } else if (date instanceof String || typeof date === 'string') {
      value = datelib(date as string).toDate()
    } else if (date instanceof Number || typeof date === 'number') {
      value = DateOnly.numberToDate(date as number)
    } else if (date instanceof DateOnly) {
      value = (date as DateOnly).toDate()
    } else if (date instanceof Timestamp) {
      value = datelib(
        datelib.utc((date as Timestamp).toDate()).format('YYYY-MM-DD')
      ).toDate()
    } else if (datelib.isDayjs(date)) {
      value = date.toDate()
    }
    this.saveDateOnly(value ?? new Date())
  }

  static toDate(
    dateOnlyStamp: string | number | Date | DateOnly | Timestamp | DatelibType
  ) {
    return new DateOnly(dateOnlyStamp).toDate()
  }

  static isEqual(a: DateOnlyConfigType, b: DateOnlyConfigType) {
    return new DateOnly(a).isSame(b)
  }

  saveDateOnly(date: DateOnly | Date) {
    this._year = date.getFullYear()
    this._month = date.getMonth()
    this._date = date.getDate()
  }

  getDate() {
    return this._date
  }

  setDate(date: number) {
    this._date = date
  }

  getMonth() {
    return this._month
  }

  setMonth(month: number) {
    this._month = month
  }

  getFullYear() {
    return this._year
  }

  setFullYear(year: number) {
    this._year = year
  }

  getDay() {
    return this.toDate().getDay()
  }

  weekday() {
    return datelib.weekdays()[datelib(this.toDate()).weekday()]
  }

  weekdayShort() {
    return datelib.weekdaysShort()[datelib(this.toDate()).weekday()]
  }

  weekdayMin() {
    return datelib.weekdaysMin()[datelib(this.toDate()).weekday()]
  }

  valueOf() {
    if (isNaN(this._year) || isNaN(this._month) || isNaN(this._date)) {
      return NaN
    }
    return parseInt(
      DateOnly.pad(this._year, 4) +
        DateOnly.pad(this._month + 1, 2) +
        DateOnly.pad(this._date, 2),
      10
    )
  }

  // converters

  toDate() {
    return DateOnly.partsToDate(this._year, this._month, this._date)
  }

  toString() {
    return this.toDate().toDateString()
  }

  toISOString() {
    // YYYY-MM-DD where YYYY (0-999), MM (1-12) and DD (1-31)
    return (
      DateOnly.pad(this._year, 4) +
      '-' +
      DateOnly.pad(this._month + 1, 2) +
      '-' +
      DateOnly.pad(this._date, 2)
    )
  }

  toJSON() {
    return this.valueOf()
  }

  toTimestamp() {
    return Timestamp.fromDate(this.toDate())
  }

  toDatelib() {
    return datelib(this.toDate())
  }

  // tests

  isValid() {
    return this.toDatelib().isValid()
  }

  isSame(date: DateOnlyConfigType) {
    return this.valueOf() === new DateOnly(date).valueOf()
  }

  isBefore(date: DateOnlyConfigType) {
    return this.valueOf() < new DateOnly(date).valueOf()
  }

  isAfter(date: DateOnlyConfigType) {
    return this.valueOf() > new DateOnly(date).valueOf()
  }

  isSameOrBefore(date: DateOnlyConfigType) {
    return this.valueOf() <= new DateOnly(date).valueOf()
  }

  isSameOrAfter(date: DateOnlyConfigType) {
    return this.valueOf() >= new DateOnly(date).valueOf()
  }

  isBetween(aDate: DateOnlyConfigType, bDate: DateOnlyConfigType) {
    [aDate, bDate] =
      new DateOnly(aDate).valueOf() > new DateOnly(bDate).valueOf()
        ? [bDate, aDate]
        : [aDate, bDate]
    return this.isSameOrAfter(aDate) && this.isSameOrBefore(bDate)
  }

  isDayBeforeYesterday() {
    return this.isSame(DateOnly.beforeYesterday)
  }
  isYesterday() {
    return this.isSame(DateOnly.yesterday)
  }
  isToday() {
    return this.isSame(DateOnly.today)
  }
  isTomorrow() {
    return this.isSame(DateOnly.tomorrow)
  }
  isDayAfterTomorrow() {
    return this.isSame(DateOnly.afterTomorrow)
  }

  add(value: number, unit?: DateOnlyManipulateType | undefined) {
    return new DateOnly(datelib(this.toISOString()).add(value, unit))
  }

  addDays(value: number) {
    return this.add(value, 'days')
  }

  addMonths(value: number) {
    return this.add(value, 'months')
  }

  addYears(value: number) {
    return this.add(value, 'years')
  }

  subtract(value: number, unit?: DateOnlyManipulateType | undefined) {
    return new DateOnly(datelib(this.toISOString()).subtract(value, unit))
  }

  startOf(step: DateOnlyManipulateType) {
    return new DateOnly(datelib(this.toISOString()).startOf(step))
  }

  endOf(step: DateOnlyManipulateType) {
    return new DateOnly(datelib(this.toISOString()).endOf(step))
  }

  daysInMonth() {
    return datelib(this.toISOString()).daysInMonth()
  }

  format(template: string | undefined) {
    return this.toDatelib().format(template)
  }

  static pad(str: number, length: number) {
    return str.toString().padStart(length, '0')
  }

  static numberToDate(n: number) {
    const s = n.toString()
    if (s.length > 10) {
      // Unix timestamp in milliseconds (13 digits, since the Unix Epoch Jan 1 1970 12AM UTC)
      return datelib(n).toDate()
    } else {
      // Unix timestamp in seconds (10 digits, seconds since the Unix Epoch)
      return datelib.unix(n).toDate()
    }
  }

  static partsToDate(year: number, month: number, day: number | undefined) {
    const date = new Date(year, month, day)
    return date
  }

  static toTimestamp(date = new DateOnly()) {
    return Timestamp.fromDate(date.toDate())
  }

  static get beforeYesterday() {
    return new DateOnly().addDays(-2)
  }
  static get yesterday() {
    return new DateOnly().addDays(-1)
  }
  static get today() {
    return new DateOnly()
  }
  static get tomorrow() {
    return new DateOnly().addDays(1)
  }
  static get afterTomorrow() {
    return new DateOnly().addDays(2)
  }

  static compare(a: DateOnlyConfigType, b: DateOnlyConfigType, isAsc = true) {
    const _aDate = new DateOnly(a)
    const _bDate = new DateOnly(b)

    return ((_aDate.isBefore(_bDate)) ? -1 : (_aDate.isAfter(_bDate)) ? 1 : 0) * (isAsc ? 1 : -1)
  }
}
