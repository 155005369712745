import { AfterViewInit, ApplicationRef, Component, HostBinding, inject, isDevMode, ViewContainerRef } from '@angular/core'
import { RouterModule } from '@angular/router'
import { debounceTime, distinctUntilChanged } from 'rxjs'
import { LongtailService } from '@nx-superprep/backend/firebase'
import { BreakpointService, FcmService, OrientationService, UpdateService } from '@nx-superprep/shared'
import { SmartBannerPlatform, SmartBannerService } from '@nx-superprep/smart-banner'
import { log } from '@nx-superprep/utils'
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'

@Component({
  standalone: true,
  selector: 'popstudy2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterModule,
    TourMatMenuModule,
    MatCardModule, MatButtonModule, MatIconModule,
  ],
})
export class AppComponent implements AfterViewInit {
  title = 'AppComnponent'
  window: (Window & typeof globalThis) | undefined
  // safe area
  @HostBinding('style.padding-top') paddingTop = '0px'
  @HostBinding('style.padding-bottom') paddingBottom = '0px'
  @HostBinding('style.padding-left') paddingLeft = '0px'
  @HostBinding('style.padding-right') paddingRight = '0px'
/*
  @HostListener('window:touchstart', ['$event']) onTouchStart(): void {
    if (!this.breakpointService.isHandset) {  return }
    this.orientationService.lockOrientation('portrait-primary').then((success) => {
      if (success && window && 'visualViewport' in window) {
        // Apply safe area insets using CSS environment variables
        this.paddingTop = `env(safe-area-inset-top, 0px)`
        this.paddingBottom = `env(safe-area-inset-bottom, 0px)`
        this.paddingLeft = `env(safe-area-inset-left, 0px)`
        this.paddingRight = `env(safe-area-inset-right, 0px)`
      }
    })
  }
*/
  logo = { src: '/assets/icons/popstudy.svg', alt: 'popstudy-logo', width: 156, height: 30 }

  readonly tourService = inject(TourService)
  readonly appRef = inject(ApplicationRef)
  readonly updateService = inject(UpdateService)
  readonly messageService  = inject(FcmService)
  readonly longtailService = inject(LongtailService)
  readonly breakpointService = inject(BreakpointService)
  readonly orientationService = inject(OrientationService)
  private readonly smartBannerService = inject(SmartBannerService)
  private readonly viewContainerRef = inject(ViewContainerRef)

  constructor() {
    log.debug(window.navigator.userAgent)
  }

  ngAfterViewInit() {
    document.documentElement.setAttribute("data-browser", navigator.userAgent)
    if (isIOSSafari()) { document.body.classList.add("mobile-safari") } else { document.body.classList.remove("mobile-safari") }
    if (isSafari()) { document.body.classList.add("desktop-safari") } else { document.body.classList.remove("desktop-safari") }
    this.updateService.check().then((updated) => {
      if (updated) { this.longtailService.logAppUpdate()} else { this.longtailService.logAppOpen('AppComponent')}
    })
    this.smartBannerService.initialize({
      viewContainerRef: this.viewContainerRef,
      title: 'PopStudy',
      author: 'Longtail Education',
      price: 'Free',
      priceSuffix: {ios: ' - On the App Store', android: ' - In Google Play'},
      icon: {ios: "/assets/favicons/apple-app-store.svg", android: "/assets/favicons/google-play.svg"},
      closeLabel: 'Close',
      buttonUrl: { ios: "https://apps.apple.com/us/app/longtail-education/id1169672977?mt=8", android: 'https://popstudy.conm'},
      enabledPlatforms: [SmartBannerPlatform.IOS],
      daysHidden: 15,
      daysReminder: 90,
      hideRating: true,
    })
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('./offline.worker', import.meta.url));
      worker.onmessage = ({ data }) => {
        console.log(`page got message ${data}`);
      };
      worker.postMessage('hello');
    } else {
      console.log('no worker')
    }
    if (isDevMode()) {
      this.appRef.isStable.pipe(
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe(() => {
        //log.debug('isStable', it)
      })
    }
    this.tourService.end()
  }

  isCenteredAnchor(id: string) {
    return  ['anchor.centered.icon','anchor.centered.image'].includes(id)
  }
}

function agentHas(keyword: string) {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

function isIOSSafari() {
  const iOS = agentHas('iPad') || agentHas('iPhone')
  const webkit = agentHas('WebKit')
  const iOSSafari = iOS && webkit && !agentHas('CriOS')
  return iOSSafari
}

function isSafari() {
  return (!!('ApplePaySetupFeature' in window) || !!('safari' in window)) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS")
}
