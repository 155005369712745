import { MatPaginator } from '@angular/material/paginator'

export enum _keyCodes {
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  LEFT_ARROW = 'ArrowLeft',
  RIGHT_ARROW = 'ArrowRight',
  HOME = 'Home',
  END = 'End',
  SPACE = 'Space',
}

export const keyAction = <E>(
  event: Partial<KeyboardEvent>,
  index: number,
  elements: E[],
  paginator?: MatPaginator
): E | undefined => {

  let element
  if (event.code === _keyCodes.UP_ARROW && index > 0) {
    index = Math.max(0, index - 1)
    element = elements[index]
    if (paginator && index < paginator.pageIndex * paginator.pageSize) { paginator.previousPage(); }
  }
  if (event.code === _keyCodes.DOWN_ARROW && index < elements.length - 1) {
    index = Math.min(elements.length - 1, index + 1)
    element = elements[index]
    if (paginator && index >= (paginator.pageIndex + 1) * paginator.pageSize) { paginator.nextPage(); }
  }
  if (event.code === _keyCodes.HOME && index > 0) {
    element = elements[0]
    paginator?.firstPage()
  }
  if (event.code === _keyCodes.END && index < elements.length - 1) {
    element = elements[elements.length - 1]
    paginator?.lastPage()
  }
  if (event.code === _keyCodes.SPACE && index >= 0) {
    element = elements[index]
  }
  return element
}
