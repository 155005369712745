import { toDefaultNumber, unique } from './utils'

/**
 * Shorthand for possible asset type
 */
export const enum AssetType {
  text = 'text',
  audio = 'audio',
  image = 'image',
  svg = 'svg',
  video = 'video',
  icon = 'icon',
  archive = 'archive',
  unknown = 'unknown'
}

/**
 * Shorthand for possible mimetypes of content
 */
export const enum MimeType {
  text = 'text/html',
  audio = 'audio/mp3',
  audiompeg = 'audio/mpeg',
  image = 'image/png',
  svg = 'image/svg+xml',
  video = 'video/mp4',
  videompeg = 'video/mpeg',
  zip = 'application/x-zip-compressed',
  unknown = 'unknown',
}

// helpers
export const isAudio = (type: string) => type?.startsWith('audio')
export const isVideo = (type: string) => type?.startsWith('video')
export const isImage = (type: string) => type?.startsWith('image')
export const isSVG = (type: string) => type?.startsWith('image/svg')
export const isText = (type: string) => type?.startsWith('text')
export const isHTML = (type: string) => type === MimeType.text
export const isArchive = (type: string) => ['application/x-zip-compressed','application/zip'].includes(type)

/**
 * Return a list of possible mimeTypes for a given asset type
 *
 * @param assetType asset content type
 */
export const getMimeTypes = (assetType: string | string[] = '') => {
  const mimeTypes: string[] = []
  const types = assetType instanceof Array ? assetType : [assetType]
  types.forEach(t => {
    switch(t) {
      case AssetType.audio:
        mimeTypes.push('audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/aac')
        break
      case AssetType.video:
        mimeTypes.push('video/mpeg', 'video/mp4', 'video/quicktime')
        break
      case AssetType.icon:
      case AssetType.image:
        mimeTypes.push('image/png', 'image/jpeg', 'image/webp')
        break
      case AssetType.svg:
        mimeTypes.push('image/svg+xml')
        break
      case AssetType.text:
        mimeTypes.push('text/plain', 'text/csv', 'text/html')
        break
      case AssetType.archive:
        mimeTypes.push('application/x-zip-compressed', 'application/zip')
        break
    }
  })
  return unique(mimeTypes) as string[]
}

export const getAssetType = (mimeType: string) => {
  if (isAudio(mimeType)) {
    return AssetType.audio
  }
  if (isVideo(mimeType)) {
    return AssetType.video
  }
  if (isImage(mimeType)) {
    return isSVG(mimeType) ? AssetType.svg : AssetType.image
  }
  if (isText(mimeType)) {
    return AssetType.text
  }
  if (isArchive(mimeType)) {
    return AssetType.archive
  }
  return undefined
}

export const getMimeExtension = (type: string) => {
  if (isAudio(type)) {
    return 'mp3'
  }
  if (isVideo(type)) {
    return 'mp4'
  }
  if (isImage(type)) {
    return isSVG(type) ? 'svg' : type.replace('image/', '')
  }
  if (isText(type)) {
    return 'txt'
  }
  if (isArchive(type)) {
    return 'zip'
  }
  return undefined
}
/**
 * return mimeType base component
 */
export const getMimeBase = (type: string) => type?.replace(/\/.*$/g, '')

export const getMimeType = (fileExtension: string) => {
  let type: string
  let extension: string

  switch (fileExtension) {
    case 'svg':
      type = 'image'
      extension = 'svg+xml'
      break
    case 'jpg':
    case 'jpeg':
      type = 'image'
      extension = 'jpeg'
      break
    case 'png':
      type = 'image'
      extension = 'png'
      break
    case 'webp':
      type = 'image'
      extension = 'webp'
      break
    case 'mp3':
      type = 'audio'
      extension = 'mpeg'
      break
    case 'mp4':
      type = 'video'
      extension = 'mp4'
      break
    case 'mpeg':
      type = 'video'
      extension = 'mpeg'
      break
    case 'ogg':
      type = 'audio'
      extension = 'ogg'
      break
    case 'oga':
      type = 'audio'
      extension = 'ogg'
      break
    case 'ogv':
      type = 'video'
      extension = 'ogg'
      break
    case 'wav':
      type = 'audio'
      extension = 'wav'
      break
    case 'zip':
      type = 'application'
      extension = 'x-zip-compressed'
      break
    default:
      type = 'application'
      extension = 'octet-stream'
  }
  return type + '/' + extension.replace(/\./g, '')
}


export const getHash = (name: string, assetType: string) => name + '_' + assetType

const _rx1 = /(.*)\/+([^/]*)$/; // (dir/)     (optional_file)
const _rx2 = /()(.*)$/;         // ()         (file)
const _rx3 = /(.*)\.+([^.]*)$/; // (filename) (optional_extension)
const _rx4 = /(.*)()$/;         // ()         (extension)

//const _rx5 = /(@[1-3]x)?(~[^.]+)?((\.[^.]*)+)?$/; // () (scale) (idiom) (optional_extension_group) (optional_extension)

/* Return directory and filename
*
* @param path complete path
*   [0]  original string
*   [1]  dirname
*   [2]  filename
*/
//const _dirAndFile = (path) => _rx1.exec(path) || _rx2.exec(path)

// Single purpose versions.
export const getFileDirname = (path: string) => {
  if (!path) { return ''; }
  const r = _rx1.exec(path) ?? _rx2.exec(path) ?? [] as unknown as RegExpExecArray
  return r[1] ?? ''
}
export const getFileBasename = (path: string) => {
  if (!path) { return ''; }
  const r =  _rx1.exec(path) ?? _rx2.exec(path) ?? [] as unknown as RegExpExecArray
  return r[2] ?? ''
}
export const getFilename = (path: string) => {
  const _basename = getFileBasename(path)
  if (!_basename) { return ''; }
  const r = _rx3.exec(_basename) ?? _rx4.exec(_basename) ?? [] as unknown as RegExpExecArray
  return r[1] ?? ''
}

export const getFileExtension = (path: string) => {
  const _basename = getFileBasename(path)
  if (!_basename) { return ''; }
  const r = _rx3.exec(_basename) ?? _rx4.exec(_basename) ?? [] as unknown as RegExpExecArray
  return r[2] ?? ''
}

export const getDuration = (size: number, mime: string) => {
  size = toDefaultNumber(size, 0)
  switch (getMimeBase(mime)) {
    case AssetType.video:
      return size / (((192 + 314) / 8) * 1024)
    case AssetType.audio:
      return size * 1024 / (44100 * 128 * 2 * 8)
    default:
      return 0
  }
}
