import { BehaviorSubject } from 'rxjs'

export class Timer {
  private readonly _timer = new BehaviorSubject<'start'|'pause'|'stop'|'clear'|'reset'>('reset')

  private _startTime: Date | undefined
  private _stopTime: Date | undefined

  private _initTimer() {
    this._timer.subscribe((state) => {
      if (state === 'start') {
        this._startTime = new Date()
        this._stopTime = undefined
      }
      if (['pause', 'stop'].includes(state)) {
        if (!this._stopTime) {
          this._stopTime = new Date()
        } else {
          this._stopTime = undefined
        }
        if (!this._startTime) {
          this._startTime = this._stopTime
        }
      }
      if (['reset', 'clear'].includes(state)) {
        this._startTime = undefined
        this._stopTime = undefined
      }
    })
  }

  constructor() {
    this._initTimer()
  }

  /** return the number of seconds since started */
  get value() {
    return !this._startTime && !this._stopTime
      ? 0
      : ((this._stopTime ?? new Date()).valueOf() - (this._startTime ?? new Date()).valueOf()) / 1000
  }
  set value(value) {
    this._startTime = new Date()
    this._stopTime = new Date(this._startTime.valueOf() + value * 1000)
  }
  /**
   * @param limit - maximum value in seconds
   * @returns current timer value in seconds
   */
  getValue(limit = 0) {
    return limit > 0 ? Math.min(this.value, limit) : this.value
  }

  isRunning() {
    return !!this._startTime && !this._stopTime
  }
  start() {
    this._timer.next('start')
  }
  pause() {
    this._timer.next('pause')
  }
  stop() {
    this._timer.next('stop')
  }
  clear() {
    this._timer.next('clear')
  }
  reset() {
    this._timer.next('reset')
  }
}
