import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreakpointService {

  /**
   * Emits an event when a breakpoint or orientation change is detected.
   * @type {Subject<void>}
   */
  readonly didChange = new Subject<void>();

  /**
   * Stores the current breakpoint.
   * @private
   * @type {string}
   */
  private _currentBreakpoint = '';

  /**
   * Stores the current orientation.
   * @private
   * @type {string}
   */
  private _currentOrientation = '';

  /**
   * Observes the screen size and orientation for changes. Monitors breakpoints for `Web`, `Tablet`, and `Handset`,
   * and for changes in orientation between `Portrait` and `Landscape`.
   *
   * @returns {void}
   */
  observe(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.Web,
        Breakpoints.Tablet,
        Breakpoints.Handset,
        '(orientation: portrait)',
        '(orientation: landscape)'
      ])
      .pipe(distinctUntilChanged())
      .subscribe(() => this.breakpointChanged());
  }

  /**
   * Checks if the current layout is for web.
   *
   * @returns {boolean} `true` if the layout is `Web`, otherwise `false`.
   */
  get isWeb(): boolean {
    return this._currentBreakpoint === Breakpoints.Web;
  }

  /**
   * Checks if the current layout is for handset (mobile).
   *
   * @returns {boolean} `true` if the layout is `Handset`, otherwise `false`.
   */
  get isHandset(): boolean {
    return this._currentBreakpoint === Breakpoints.Handset;
  }

  /**
   * Checks if the current orientation is portrait.
   *
   * @returns {boolean} `true` if the orientation is `Portrait`, otherwise `false`.
   */
  get isPortrait(): boolean {
    return this._currentOrientation === 'portrait';
  }

  /**
   * Checks if the current orientation is landscape.
   *
   * @returns {boolean} `true` if the orientation is `Landscape`, otherwise `false`.
   */
  get isLandscape(): boolean {
    return this._currentOrientation === 'landscape';
  }

  /**
   * Updates the current breakpoint and orientation based on the viewport size.
   * Emits an event via `didChange` subject when a change is detected.
   *
   * @private
   * @returns {void}
   */
  private breakpointChanged(): void {
    // Orientation check
    this._currentOrientation = this.breakpointObserver.isMatched('(orientation: portrait)')
      ? 'portrait'
      : 'landscape';

    // Breakpoint check
    if (this.breakpointObserver.isMatched([Breakpoints.Handset])) {
      this._currentBreakpoint = Breakpoints.Handset;
    } else if (this.breakpointObserver.isMatched([Breakpoints.Tablet])) {
      this._currentBreakpoint = Breakpoints.Tablet;
    } else if (this.breakpointObserver.isMatched([Breakpoints.Web])) {
      this._currentBreakpoint = Breakpoints.Web;
    }

    this.didChange.next();
  }

  /**
   * Constructor to initialize the service and begin observing breakpoints.
   *
   * @param {BreakpointObserver} breakpointObserver - Angular service to observe layout changes.
   */
  constructor(private breakpointObserver: BreakpointObserver) {
    this.observe();
  }
}
