import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

type StorageData = Record<string, unknown>;

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {

  private static readonly PREFIX = 'pop_';
  private static readonly KEYS = {
    USER: `${BrowserStorageService.PREFIX}user`,
    CONFIG: `${BrowserStorageService.PREFIX}config`,
    VOLUME: `${BrowserStorageService.PREFIX}volume`,
  };

  private storage: Storage | undefined
  private origin: string | undefined

  static getUser() {
    return JSON.parse(this.getItem(BrowserStorageService.KEYS.USER)??'{}')
  }
  static setUser(user: unknown) {
    BrowserStorageService.setItem(BrowserStorageService.KEYS.USER, JSON.stringify(user))
  }
  static removeUser() {
    BrowserStorageService.removeItem(BrowserStorageService.KEYS.USER)
  }

  static getConfig() {
    return JSON.parse(localStorage.getItem(BrowserStorageService.KEYS.CONFIG)??'{}')
  }
  static setConfig(config: unknown) {
    localStorage.setItem(BrowserStorageService.KEYS.CONFIG, JSON.stringify(config))
  }

  static upgradeConfig(data: StorageData): StorageData {
    const oldKeys = ['user', 'config', 'volume'];
    for (const key of oldKeys) {
      const value = BrowserStorageService.getItem(key);
      if (value) {
        this.setItem(`${BrowserStorageService.PREFIX}${key}`, JSON.parse(value));
        this.removeItem(key);
      }
    }
    BrowserStorageService.setConfig(data);
    return BrowserStorageService.getConfig();
  }

  static removeConfig() {
    BrowserStorageService.removeItem(BrowserStorageService.KEYS.CONFIG)
  }

  static getVolume() {
    return Number(BrowserStorageService.getItem(BrowserStorageService.KEYS.VOLUME))||1
  }
  static setVolume(volume: number) {
    BrowserStorageService.setItem(BrowserStorageService.KEYS.VOLUME, volume.toString())
  }
  static removeVolume() {
    BrowserStorageService.removeItem(BrowserStorageService.KEYS.VOLUME)
  }

  static getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  static setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.storage = isPlatformBrowser(this.platformId) ? localStorage : undefined
    this.origin = isPlatformBrowser(this.platformId) ? window.location.origin.slice() : undefined
  }

  getItem(key: string) { return this.storage ? BrowserStorageService.getItem(key) : undefined }
  setItem(key: string, value: string) { return this.storage ? BrowserStorageService.setItem(key, value) : undefined }
  removeItem(key: string) { if (this.storage) { BrowserStorageService.removeItem(key) }}
  getUser() { return this.storage ? BrowserStorageService.getUser() : undefined }
  setUser(value: unknown | undefined) { return this.storage ? BrowserStorageService.setUser(value) : undefined }
  removeUser() { if (this.storage) { BrowserStorageService.removeUser() }}
  getConfig() { return this.storage ? BrowserStorageService.getConfig() : undefined }
  setConfig(value: unknown) { return this.storage ? BrowserStorageService.setConfig(value) : undefined }
  removeConfig() { if (this.storage) { BrowserStorageService.removeConfig() }}
  getVolume() { return Number(this.storage ? BrowserStorageService.getVolume() : 1) || 1 }
  setVolume(value: number) { return this.storage ? BrowserStorageService.setVolume(value) : value }
  removeVolume() { if (this.storage) { BrowserStorageService.removeVolume() }}
  upgradeConfig(data: StorageData) { return this.storage ? BrowserStorageService.upgradeConfig(data) : undefined }
}
